<template>
  <div>
    <a-table
      class="alert-table-mobile"
      row-key="id"
      :columns="columns"
      :data-source="value"
      :loading="isFetching"
      :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      :custom-row="rowClicked"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template #alert="{ record }">
        <div class="alert-item">
          <div class="columns is-mini-gap is-mobile m-b-0">
            <div class="column">
              <div class="has-text-weight-bold">
                {{ getTitle(record) }}
              </div>
            </div>
            <div class="column is-narrow is-size-8">
              {{ $filters.date(record.createdAt) }}
              <div v-show="!record.readAt" class="circle-danger" />
            </div>
          </div>
          <p>{{ getDescription(record) }}</p>
          <p>
            {{ record.property ? record.property.name : '-' }},
            {{ record.room ? record.room.name : '-' }}
          </p>
          <div class="has-text-right is-block">
            <Delete :record="record" @delete="$emit('delete')" />
          </div>
        </div>
      </template>
    </a-table>
  </div>
</template>

<i18n src="../locales.json"></i18n>

<script>
import Delete from '@/views/alerts/components/DeleteAlert';
import list from '@/views/alerts/mixins/list';

export default {
  name: 'AlertsListMobi',
  components: {
    Delete,
  },
  mixins: [list],
  emits: ['delete'],
  data() {
    return {
      columns: [
        {
          title: this.$t('Alert'),
          dataIndex: 'alert',
          slots: { customRender: 'alert' },
        },
      ],
    };
  },
  methods: {
    rowClicked(record) {
      return {
        onClick: () => {
          this.showDetail(record);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-table-mobile :deep(.table-striped) {
  background-color: #f5f5f5;
}

.alert-item {
  .has-right {
    display: flex;

    .right {
      text-align: right;
      margin-left: auto;
    }
  }
}
</style>
